<template>
  <footer class="text-white py-4">
    <div class="container mx-auto flex flex-col items-center">
      <div class="mb-4 mt-auto flex flex-col items-center">
        <img
          class="lazyload"
          src="https://i0.wp.com/abdulhalimzhr.com/old/wp-content/uploads/2023/01/logo-white.png"
          alt="Abdul Halim ZHR"
          width="60"
          height="23"
        />
        <p class="text-xs mt-3">
          &copy; 2023 by
          <a
            href="https://abdulhalimzhr.com"
            class="text-white hover:text-cyan-500"
            >Abdul Halim ZHR</a
          >
        </p>
      </div>
      <div class="flex space-x-4">
        <a
          href="https://github.com/abdulhalimzhr"
          class="text-xl text-gray-600 hover:text-cyan-500"
          aria-label="GitHub"
        >
          <span class="fa-stack fa-1x" style="flex-shrink: 0">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fab fab fa-github fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <a
          href="https://abdulhalimzhr.medium.com/"
          class="text-xl text-gray-600 hover:text-cyan-500"
          aria-label="Medium"
        >
          <span class="fa-stack fa-1x" style="flex-shrink: 0">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fab fa-medium fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <a
          href="https://www.linkedin.com/in/abdulhalimzhr/"
          class="text-xl text-gray-600 hover:text-cyan-500"
          aria-label="LinkedIn"
        >
          <span class="fa-stack fa-1x" style="flex-shrink: 0">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fab fa-linkedin fa-stack-1x fa-inverse"></i>
          </span>
        </a>
      </div>
    </div>
  </footer>
</template>

<style scoped>
/* Add any additional styling if needed */
a {
  &:hover {
    @apply transition duration-200 ease-linear;
  }
}
</style>
