import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
      metaTags: [
        {
          name: 'description',
          content: 'Abdul Halim\'s Profile'
        },
        {
          property: 'og:description',
          content: 'Abdul Halim\'s Profile'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'About Me',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of the Author'
        },
        {
          property: 'og:description',
          content: 'The about page of the Author'
        }
      ]
    }
  },
  {
    path: '/portofolio',
    name: 'portofolio',
    component: () => import('../views/PortofolioView.vue'),
    meta: {
      title: 'Portofolio',
      metaTags: [
        {
          name: 'description',
          content: 'Abdul Halim\'s Portofolio'
        },
        {
          property: 'og:description',
          content: 'Abdul Halim\'s Portofolio'
        }
      ]
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
  const nearestWithTitle: any = to.matched.slice().reverse().find(r => r.meta && r.meta.title)?.meta.title;
  const nearestWithMeta: any = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)?.meta.metaTags;
  const previousNearestWithMeta: any = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)?.meta.metaTags;

  document.title = process.env.VUE_APP_NAME;

  if (nearestWithTitle) {
    document.title += ' - ' + nearestWithTitle.toString();
  } else if (previousNearestWithMeta) {
    document.title += ' - ' + previousNearestWithMeta.meta?.title;
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el));

  if (!nearestWithMeta) {
    return next();
  }

  nearestWithMeta.map((tagDef: any) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach((tag: any) => document.head.appendChild(tag));

  next();
});
