
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Contents',
  data() {
    return {
      document: document
    };
  }
});
