
import { defineComponent } from 'vue';
import Navbar from '@/components/section/Navbar.vue';
import Contents from '@/components/section/Content.vue';
import FooterSection from '@/components/section/Footer.vue';

export default defineComponent({
  name: 'AppView',
  components: {
    Navbar,
    Contents,
    FooterSection
  }
});
