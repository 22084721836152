import { createStore } from 'vuex';
import { fetchPosts } from './api';

export default createStore({
  state: {
    posts: {}
  },
  getters: {
    getPosts: (state) => {
      return state.posts;
    }
  },
  mutations: {
    setPosts: (state, posts) => {
      state.posts = posts;
    }
  },
  actions: {
    async fetchPosts ({ commit }) {
      const response = await fetchPosts();
      commit('setPosts', response.items);
    }
  },
  modules: {}
});
