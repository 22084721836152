
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Navbar',
  data() {
    return {
      showMobileMenu: false
    };
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    }
  }
});
